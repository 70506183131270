/* ====================== [ Start services ] ====================== */
.services {
    position: relative;
}

.services .container {
    position: relative;
    z-index: 2;
}

.services .item-box {
    padding: 80px 30px;
    text-align: center;
    background: #131620;
    border-radius: 15px;
    /* border: 1px solid #d3a0653d; */
    height: 98%;
}

.item-box {
    transition: all 0.5s;
}

.services .item-box:hover {
    box-shadow: 0px 6px var(--primary-color-dark);
    border: 1px solid #d3a0653d;
}

.mission__title {
    color: var(--primary-color-dark);
    margin-bottom: 10px;
}

.mission__background {
    background: #f7f7f7;
    padding: 0 50px;
    border-radius: 15px;
}


@media (max-width: 991px) {
    .mission__background {
        padding-bottom: 50px;
    }
}

@media (max-width: 576px) {
    .mission__background {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.services .item-box:nth-of-type(2) {
    background: #f7f7f7;
}

.services .item-box:nth-of-type(3) {
    background: #f7f7f7;
}

.services .item-box:last-of-type {
    background: #f7f7f7;
}

.services .item-box.bg-img {
    position: relative;
    text-align: left;
    z-index: 2;
}

.services .item-box.bg-img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1a1e25;
    opacity: 0.8;
    z-index: -1;
}

.services .item-box .icon {
    color: var(--primary-color-dark);
    font-size: 40px;
    margin-bottom: 30px;
}

.services .item-box h6 {
    line-height: 1.8;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.services .rone>div {
    position: relative;
}

.services .rone>div:nth-of-type(2) {
    margin-bottom: -50px;
    margin-top: auto;
}

.services .rtwo>div {
    position: relative;
}

.services .rtwo>div:nth-of-type(2) {
    margin-bottom: -50px;
    margin-top: auto;
}

.services .mas-item {
    padding: 40px;
    margin: 50px 0;
    background: #131620;
    position: relative;
    z-index: 2;
}

.services .mas-item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #131620;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    z-index: -1;
}

.services .mas-item .bg-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary-color-dark);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    z-index: -2;
}

.services .mas-item:hover .bg-color {
    top: 10px;
    left: 10px;
}

.services .mas-item .icon {
    font-size: 45px;
    color: var(--primary-color-dark);
    margin-bottom: 30px;
}

.services .mas-item h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.7;
}

.services .mas-item .more {
    margin-top: 20px;
    width: 100%;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.05);
}

.services .step-item {
    text-align: center;
    padding: 60px;
    margin: 60px auto;
    background: #151921;
    position: relative;
}

.services .step-item.xbottom {
    bottom: -60px;
}

.services .step-item.xtop {
    top: -60px;
}

.services .step-item.xcolor {
    background: var(--primary-color-dark);
    color: #131620;
}

.service__item {
    margin-bottom: 30px;
}

.services .step-item.xcolor .icon {
    color: #131620 !important;
}

.services .step-item.xcolor p {
    color: #333;
}

.services .step-item .icon {
    color: var(--primary-color-dark);
    font-size: 40px;
    margin-bottom: 30px;
}

.services .step-item h6 {
    margin-bottom: 20px;
}

.services .item {
    text-align: center;
    padding: 60px;
    background: #151921;
    position: relative;
}

.services .item .icon {
    color: var(--primary-color-dark);
    font-size: 40px;
    margin-bottom: 40px;
}

.services .item h6 {
    margin-bottom: 15px;
}

.min-area .img {
    padding: 40px 40px;
    position: relative;
    z-index: 3;
}

@media (max-width: 768px) {
    .min-area .img {
        padding: 40px 20px;
    }
}

@media (max-width: 576px) {
    .min-area .img {
        padding: 40px 0px 0px 0px;
    }
}

img.thumparallax-down {
    border-radius: 15px;
}

.min-area .content {
    text-align: start;
}

@media (max-width: 768px) {
    .min-area .content {
        padding-inline-start: 20px;
    }
}

@media (max-width: 576px) {
    .min-area .content {
        padding-inline-start: 0;
    }
}

.min-area .content h4 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.min-area .content ul {
    margin: 0;
}

.min-area .content ul li {
    margin-top: 25px;
    padding-left: 25px;
    position: relative;
}

.min-area .content ul li:after {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #999;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 2px;
}

.min-area .content ul.feat li {
    padding: 0 0 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.min-area .content ul.feat li:last-of-type {
    padding: 0;
    border: 0;
}

.min-area .content ul.feat li:after {
    display: none;
}

.min-area .content ul.feat li h6 {
    font-weight: 500;
    margin-bottom: 5px;
}

.min-area .content ul.feat li h3 span {
    color: var(--primary-color-dark);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    border: 1px solid var(--primary-color-dark);
    border-radius: 50%;
    margin-inline-end: 10px;
}

.min-area .content ul.feat li h6 span {
    color: var(--primary-color-dark);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    border: 1px solid var(--primary-color-dark);
    border-radius: 50%;
    margin-inline-end: 10px;
}

/* .min-area .content ul.feat li h6 {
    color: #fff;

} */

.min-area .content ul.feat li p {
    font-size: 18px;
    padding-inline-end: 45px;
}

@media (max-width: 768px) {
    .min-area .content ul.feat li p {
        padding-inline-end: 0;
    }
}

/* ========================================================= */

.values .img {
    padding: 40px 40px;
    position: relative;
    z-index: 3;
}

img.thumparallax-down {
    border-radius: 15px;
}

.values .content {
    text-align: start;
}

@media (max-width: 767px) {
    .values .img {
        padding: 40px 20px;
    }

    .values .content {
        padding-inline-start: 20px;
    }
}

@media (max-width: 576px) {
    .values .img {
        padding: 40px 0px 30px 0px;
    }

    .values .content {
        padding-inline-start: 0;
    }
}

.values .content h4 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.values .content ul {
    margin: 0;
}

.values .content ul li {
    margin-top: 10px;
    padding-left: 25px;
    position: relative;
}

.values .content ul li:after {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #999;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 2px;
}

.values .content ul.feat li {
    padding: 0 0 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.values .content ul.feat li:last-of-type {
    padding: 0;
    border: 0;
}

.values .content ul.feat li:after {
    display: none;
}

.values .content ul.feat li h6 {
    font-weight: 700;
    margin-bottom: 5px;
}

.values .content ul.feat li h3 span {
    color: var(--primary-color-dark);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    border: 1px solid var(--primary-color-dark);
    border-radius: 50%;
    margin-inline-end: 10px;
}

.values .content ul.feat li h6 span {
    color: var(--primary-color-dark);
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 13px;
    border: 1px solid var(--primary-color-dark);
    border-radius: 50%;
    margin-inline-end: 10px;
}

.values .content ul.feat li h6 {
    color: #777;

}

.values .content ul.feat li p {
    font-size: 13px;
    padding-left: 45px;
}

h6.head-secondary {
    max-width: 800px;
    margin: auto;
    padding: 10px 20px 0;
    line-height: 28px;
}

/* ====================== [ End services ] ====================== */
@media screen and (max-width: 767px) {
    .sm-mb10 {
        margin-bottom: 10px;
    }

    .sm-mb20 {
        margin-bottom: 20px;
    }

    .sm-mb30 {
        margin-bottom: 30px;
    }

    .content.sm-mb30 {
        text-align: center;
    }

    .sm-mb40 {
        margin-bottom: 40px;
    }

    .sm-mb50 {
        margin-bottom: 50px;
    }

    .sm-mb60 {
        margin-bottom: 60px;
    }

    .sm-mb70 {
        margin-bottom: 70px;
    }

    .sm-mb80 {
        margin-bottom: 80px;
    }

    .sm-pb10 {
        padding-bottom: 10px;
    }

    .sm-pb20 {
        padding-bottom: 20px;
    }

    .sm-pb30 {
        padding-bottom: 30px;
    }

    .sm-pb40 {
        padding-bottom: 40px;
    }

    .sm-pb50 {
        padding-bottom: 50px;
    }

    .sm-pb60 {
        padding-bottom: 60px;
    }

    .sm-pb70 {
        padding-bottom: 70px;
    }

    .sm-pb80 {
        padding-bottom: 80px;
    }

    h1 {
        font-size: 35px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 16px;
    }

    .progress-wrap {
        right: 15px;
        bottom: 15px;
    }

    .navbar .logo {
        width: 40px;
    }

    .hamenu .menu-links .main-menu>li {
        font-size: 24px;
    }

    .hamenu .menu-links .main-menu .sub-menu li {
        font-size: 18px;
    }

    .topnav .container-fluid {
        padding: 0 30px;
    }

    .topnav .logo {
        width: 40px;
    }

    .works-header {
        min-height: 60vh;
    }

    .btn-curve {
        padding: 10px 29px;
    }

    .btn-curve span {
        font-size: 15px;
    }

    .slider .parallax-slider .caption h1 {
        font-size: 40px;
    }

    .slider .parallax-slider .caption h1 br {
        display: none;
    }

    .slider .parallax-slider .swiper-slide-active .caption h1 {
        font-size: 30px;
    }

    .slider .social-icon {
        display: none;
    }

    .slider .setone.setwo .prev-ctrl {
        left: 15px;
        bottom: 15px;
        top: auto;
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .slider .setone.setwo .next-ctrl {
        left: 70px;
        bottom: 15px;
        top: auto;
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .slider .swiper-pagination-fraction.top.botm {
        right: 15px;
        bottom: 15px;
    }

    .slider .swiper-pagination-fraction.top .swiper-pagination-current {
        font-size: 40px;
    }

    .slider .parallax-slider .caption.dig h1 {
        font-size: 40px;
    }

    .slider-bus .caption h1 {
        font-size: 70px;
    }

    .freelancer .cont h1 {
        font-size: 60px;
    }

    .slider-bus .setone {
        bottom: 20px;
        right: 20px;
    }

    .slider-bus .swiper-pagination-fraction {
        left: 20px;
        bottom: 20px;
    }

    .showcase-carus .swiper-slide .bg-img {
        margin: 0;
        height: 100vh;
        background-position: center center;
    }

    .showcase-carus.circle-slide .caption h1,
    .showcase-carus.circle-slide .copy-cap h1 {
        font-size: 50px;
        min-width: auto;
        margin: auto !important;
        left: 0;
    }

    .works-header .capt h2 {
        font-size: 40px;
    }

    .slider.fixed-slider,
    .works-header.hfixd {
        position: static;
    }

    .main-content {
        padding-top: 40px !important;
    }

    .about .img-mons .img1,
    .about .img-mons .img2,
    .about .img-mons .img3 {
        margin-bottom: 10px;
    }

    .about .img-mons .img3 {
        width: 100%;
        margin-top: 0;
    }


    .sec-head h3 {
        font-size: 45px;
        font-weight: 700;
    }

    .sec-head h6 {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    .hamenu .cont-info {
        display: none;
    }

    .hamenu .menu-links .main-menu>li {
        font-size: 20px;
        padding: 7px;
    }

    .hamenu .menu-links .main-menu .sub-menu li {
        font-size: 16px;
    }

    .topnav .container-fluid {
        padding: 0 15px;
    }

    .pages-header .cont .path {
        font-size: 15px;
    }

    .pages-header .cont .path span {
        margin: 0 10px;
    }

    .slider-bus .caption h1 {
        font-size: 50px;
    }

    .slider .swiper-pagination-fraction.steps {
        bottom: 60px;
    }

    .circle-slide .swiper-slide .bg-img {
        height: 280px !important;
        width: 280px !important;
    }

    .showcase-carus.circle-slide .caption h1,
    .showcase-carus.circle-slide .copy-cap h1 {
        font-size: 40px;
    }

    .works-header .capt h2 {
        font-size: 30px;
        letter-spacing: 1px;
    }

    .sec-head h3 {
        font-size: 35px;
    }

    .about .blc-img .skills-circle {
        display: block;
        padding-bottom: 30px !important;
    }

    .about .blc-img .skills-circle .item {
        width: 100%;
    }

    .about .blc-img .skills-circle .item:last-of-type {
        margin-left: 0;
    }

}