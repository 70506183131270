/* ====================== [ Start contact ] ====================== */
.contact-sec .form .form-group {
    margin-bottom: 30px;
}

.contact-sec .form .form-group input,
.contact-sec .form .form-group textarea {
    width: 100%;
    padding: 15px 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: transparent;
    font-family: "Barlow Condensed", sans-serif;
}

.contact-sec .form button {
    font-size: 17px;
    background: transparent;
    border: 0;
}


.contact .form input,
.contact .form textarea {
    width: 100%;
    padding: 15px 0;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.contact .form textarea {
    padding: 15px;
    min-height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 30px;
}

.contact .cont-info h3 {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.map-icon-mt-10 {
    margin-top: 4px;
}

.contact .cont-info .item h5 a {
    color: #777;
}

.contact .cont-info .item .mail-color {
    color: var(--primary-color);
}

.contact .cont-info .item i {
    color: #999;
}

.contact .cont-info .item h5 {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    margin: 5px 0;
}


.contact .cont-info .item h6 {
    font-size: 14px;
    font-weight: 300;
    color: #777;
    line-height: 1.7;
}

.contact .cont-info .social a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: rgb(18 18 18 / 3%);
    border-radius: 50%;
    margin-right: 5px;
    font-size: 13px;
}

.contact .cont-info .social a:hover {
    background: white;
}

.map {
    width: 100%;
    height: 60vh;
}

.contact {
    background-color: #f7f7f7;
}

.contact_info {
    background: rgb(18 18 18 / 3%);
    padding: 10px;
    width: 170px;
    border-radius: 12px;
}

/* ====================== [ End contact ] ====================== */