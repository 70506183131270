/* ====================== [  Start Button Style ] ====================== */

/*===== All Button Style =====*/
.btn__main {
    border-radius: 10px;
    display: inline-block;
    font-style: normal;
    font-size: 16px;
    padding: 10px 40px !important;
    transition: .5s;
    position: relative;
    z-index: 0;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;

}

.btn__main:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 0;
    height: 100%;
    transition: .5s;
    z-index: -1;
}

.btn1 {
    margin: 10px 10px;
}



.btn__main.btn1 {
    background: #90d3cb;
    border-radius: 100px;
}

.btn__main.btn1:before {
    background: white;
}

.btn__main.btn1:hover {
    transform: scale(1.04);
    color: #05908d;
    border-color: white;
}

.btn__main:hover:before {
    width: 100%;
}

.main-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #90d3cb;
    padding: 0 30px;
    font-size: 15px;
    line-height: 45px;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-color: #90d3cb;
}


.main-btn:hover {

    background-color: #90d3cb;
    transform: scale(1.04);
    color: white;
    border-color: white;
}

.main-btn.main-btn-2 {
    background-color: #fff;
    color: #90d3cb;
    border-color: #90d3cb;
}

.main-btn.main-btn-2:hover {
    background-color: #90d3cb;
    border-color: #90d3cb;
    color: #fff;
}

.section-title {
    padding-bottom: 25px;
}

.btn-curve {
    background: white;
    padding: 12px 35px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 100px;
}

.btn-line {
    background: transparent;
    padding: 10px 35px;
    cursor: pointer;
    border-radius: 100px;
    color: var(--primary-background);
    border: 1px solid var(--primary-background);
    font-size: 18px;
}

.btn-line:hover {
    color: var(--primary-color);
    background-color: var(--primary-background);
}

.primary-color {
    background-color: var(--primary-color) !important;
    color: white !important;
    border-color: transparent !important;
}

.main-btn.primary-color:hover {
    background-color: var(--primary-color-dark) !important;
}

.btn-curve:focus {
    outline: none;
}

.btn-curve:hover {
    outline: none;
}

.btn-curve:hover:after {
    bottom: -30px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.btn-curve:hover span {
    color: #fff;
}

.btn-curve:after {
    content: "";
    position: absolute;
    top: -30px;
    left: -30px;
    right: -30px;
    bottom: 130%;
    background: #1e1e1e;
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}

.btn-curve span {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}

.btn-curve.btn-lit {
    border-color: #000;
    color: var(--primary-color-dark);
}

.btn-curve.btn-lit:hover span {
    color: #11141b;
}

.btn-curve.btn-lit:after {
    background: var(--primary-color);
}

.btn-form:after {
    background: white;
    color: #000;
}

.btn-form:hover span {
    color: #000;

}

.btn-form {
    background: var(--primary-color);
    color: #000;
}

.btn-curve.btn-blc {
    background: #1e1e1e;
}

.btn-curve.btn-blc:after {
    background: #fff;
}

.btn-curve.btn-blc span {
    color: #fff;
}

.btn-curve.btn-blc:hover span {
    color: #1e1e1e;
}

.btn-curve.btn-color {
    background: #11141b;
    border-color: #11141b;
}

.btn-curve.btn-color:after {
    background: #fff;
}

.btn-curve.btn-color span {
    color: #0c0f16;
}

.btn-curve.btn-color:hover span {
    color: #11141b;
}

.btn-curve.btn-lg {
    padding: 14px 40px;
}

.btn-curve.btn-lg span {
    font-size: 14px;
}

.btn-curve.btn-wit {
    background: #fff;
    border-color: #fff;
}

.btn-curve.btn-wit:after {
    background: #090909;
}

.btn-curve.btn-wit span {
    color: #1e1e1e;
}

.btn-curve.btn-wit:hover span {
    color: #fff;
}

.btn-curve.btn-radius {
    border-radius: 30px;
}

.simple-btn {
    position: relative;
    padding: 7px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 13px;
    font-weight: 300;
}

.simple-btn:after {
    content: "";
    width: 45%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    position: absolute;
    left: 0;
    top: 0;
}

.simple-btn.right:after {
    left: auto;
    right: 0;
}

.primary-color span {
    font-weight: 700;
}

.primary-color:hover:after {
    background: white;
}

.primary-color:hover span {
    color: var(--primary-color);

}

.primary-background {
    background-color: var(--color-primary);
}

/* ====================== [ End Button Style ] ====================== */