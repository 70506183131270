/* ====================== [ Start Testimonials ] ====================== */
.testimonials .item {
    text-align: center;
}

.testimonials .item p {
    font-size: 22px;
    font-weight: 300;
}

.testimonials .item .info {
    margin-top: 30px;
}

.testimonials .item .info .img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
}

.testimonials .item .info .author-name {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #d3a065;
}

.testimonials .item .info .author-details {
    font-size: 13px;
}

.testimonials .slick-dots {
    bottom: -30px;
}

.testimonials .slick-dots li {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #eee;
}

.testimonials .slick-dots li.slick-active {
    background: #eee;
}

.testimonials .slick-dots li button {
    display: none;
}

.testimonials .arrows .next,
.testimonials .arrows .prev {
    position: absolute;
    right: -50px;
    top: 50%;
    width: 50px;
    height: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #eee;
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.testimonials .arrows .next:hover,
.testimonials .arrows .prev:hover {
    border-color: #75dab4;
}

.testimonials .arrows .prev {
    right: auto;
    left: -50px;
}

.testimonials:hover .arrows .next,
.testimonials:hover .arrows .prev {
    opacity: 1;
    right: 0;
}

.testimonials:hover .arrows .prev {
    left: 0;
}

.testimonials.lftstl .item {
    text-align: left;
}

.testimonials.lftstl .item .info .author .lxleft {
    display: table-cell;
    vertical-align: middle;
}

.testimonials.lftstl .item .info .author .lxleft .img {
    margin: 0;
}

.testimonials.lftstl .item .info .author .fxright {
    display: table-cell;
    padding: 20px;
}

.testimonials.lftstl .slick-dots {
    text-align: right;
}

.block-sec .video-box .img {
    position: relative;
}

.block-sec .video-box .img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #11141b;
    opacity: 0.4;
}

.block-sec .vid-area .vid-icon .vid {
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 80px;
    position: relative;
}

.block-sec .vid-area .vid-icon .vid:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: #fff;
    border-radius: 50%;
    opacity: 0.1;
}

.block-sec .vid-area .vid-icon .vid span {
    font-size: 30px;
    color: #75dab4;
}

.block-sec .vid-area .cont {
    margin-top: 40px;
}

.block-sec .vid-area .cont h6 {
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 15px;
}

.block-sec .vid-area .cont h3 {
    font-size: 45px;
    font-weight: 700;
}

.block-sec .testim-box {
    position: relative;
    background: #75dab4;
    padding: 80px 40px;
    margin-bottom: -120px;
}

.block-sec .testim-box .head-box h6 {
    color: #0c0f16;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 10px;
}

.block-sec .testim-box .head-box h4 {
    color: #0c0f16;
    margin-bottom: 40px;
}

.block-sec .testim-box .item p {
    color: #333;
    font-size: 17px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.block-sec .testim-box .item .info .img {
    display: table-cell;
    vertical-align: middle;
}

.block-sec .testim-box .item .info .img .img-box {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
}

.block-sec .testim-box .item .info .cont {
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px;
}

.block-sec .testim-box .item .info .cont .author-name {
    color: #0c0f16;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.block-sec .testim-box .item .info .cont .author-details {
    color: #0c0f16;
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
}

.block-sec .testim-box .slick-dots {
    text-align: right;
}

.block-sec .testim-box .slick-dots li {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #0c0f16;
}

.block-sec .testim-box .slick-dots li.slick-active {
    background: #0c0f16;
}

.block-sec .testim-box .slick-dots li button {
    display: none;
}

.block-sec .showreel .video-box {
    margin-top: 200px;
    position: relative;
    margin-bottom: -100px;
    z-index: 2;
}

.block-sec .showreel .video-box:after,
.block-sec .showreel .video-box:before {
    content: "";
    position: absolute;
    width: 20%;
    height: 100%;
    top: 0;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    opacity: 0.2;
    z-index: 3;
}

.block-sec .showreel .video-box:after {
    left: 20%;
}

.block-sec .showreel .video-box:before {
    right: 20%;
}

.block-sec .showreel .video-box .tit-text {
    position: absolute;
    top: -100px;
    width: 100%;
    text-align: center;
    z-index: -1;
}

.block-sec .showreel .video-box .tit-text h3 {
    font-size: 100px;
    font-weight: 700;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
}

.block-sec .showreel .video-box .vid {
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #fff;
    position: absolute;
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    z-index: 4;
}

.block-sec .showreel .video-box .img {
    -webkit-box-shadow: 0px 10px 30px -5px rgba(52, 60, 70, 0.5);
    box-shadow: 0px 10px 30px -5px rgba(52, 60, 70, 0.5);
    border-radius: 5px;
    overflow: hidden;
}

.clients-brand .item a {
    width: 80px;
    opacity: 0.5;
}

.clients-brand .item a:hover {
    opacity: 1;
}

/* ====================== [ End Testimonials ] ====================== */