/* ====================== [ Start brands ] ====================== */
.clients .brands:hover img {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
}

.clients .brands:hover .link {
    opacity: 1 !important;
    -webkit-transition: all 0;
    -o-transition: all 0;
    transition: all 0;
}

.clients .brands:hover .char {
    -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
    animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
    -webkit-animation-delay: calc(20ms * var(--char-index));
    animation-delay: calc(20ms * var(--char-index));
}

.clients .brands .item {
    text-align: center;

    height: 160px;
    line-height: 160px;
    margin-bottom: 45px;
}

.clients .brands .img {
    position: relative;
    border: 1px solid #9a683f38;
    border-radius: 10px;
}

.clients .brands .img img {
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin: auto;
}

.clients .brands .img .link {
    font-size: 13px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    line-height: 25px;
    -ms-transform: translateX(-50%);
    max-width: 135px;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.clients .bord .item {
    margin-bottom: 30px;
}

.clients-brand .brands:hover .img {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
}

.clients-brand .brands:hover .link {
    opacity: 1 !important;
    -webkit-transition: all 0;
    -o-transition: all 0;
    transition: all 0;
}

.clients-brand .brands:hover .char {
    -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
    animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
    -webkit-animation-delay: calc(20ms * var(--char-index));
    animation-delay: calc(20ms * var(--char-index));
}

.clients-brand .item {
    position: relative;
    text-align: center;
}

.clients-brand .item .link {
    font-size: 16px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.clients-brand .img {
    max-width: 85px;
    margin: auto;
    position: relative;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.swiper-pagination-bullet-active {
    background-color: var(--primary-color) !important;
}

.client_background {
    background-color: #f7f7f7;
    border-radius: 20px;
    padding: 40px 100px;
    align-items: center;
}

.client_img {
    display: flex;
    align-items: center;
    margin-inline-start: auto;
}

@media (max-width: 576px) {
    .client_background {
        padding: 40px 20px;
        align-items: center;
    }

    .client_title {
        margin-top: 38px;
        text-align: center;
    }

    .client_img {

        margin: auto;
    }
}

/* ====================== [ End brands ] ====================== */