/* ====================== [ Start Numbers & Counter  ] ====================== */
.number-sec .item {
    text-align: center;
    position: relative;
    z-index: 2;
}



.number-sec .item:after {
    content: "";
    width: 1px;
    height: 100%;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.2;
}

@media (max-width: 768px) {
    .number-sec .item {
        margin-bottom: 50px;
    }

    .number-sec .item:after {
        background: transparent;
    }
}

.number-sec .item.no-bord:after {
    display: none;
}

.number-sec .item .icon {
    margin-bottom: 15px;
    font-size: 50px;
    width: 100%;
    opacity: 1;
    color: var(--primary-color-dark);
}

.number-sec .item h3 {
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 5px;
}

.number-sec .item p {
    color: var(--primary-color);
    font-weight: 900;
}

/* ====================== [ End Numbers & Counter  ] ====================== */