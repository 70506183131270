input.form-control,
select.form-select {
    border-radius: 100px !important;
    padding: 20px 20px;
}

.label-form {
    font-size: 16px;
    color: black;
    display: block;
    margin-right: 10px;
}

.modal-body {
    font-size: 16px;
    color: black;
}

select.form-select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 6px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-radius: 100px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
    padding: 0px 20px;

}

select::-ms-expand {
    display: none;
}

.p-time {
    padding: 0 20px !important;
}

.invalid-feedback {
    font-size: 16px;
}

.rdt {
    background: var(--color-background)
}