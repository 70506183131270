// Layouts
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

.pv-tab-button {
  display: flex;
  justify-content: center;
  list-style: none;
  background: rgba(26, 255, 141, 0.03);
  min-width: 570px;
  margin: 0 auto;
  border-radius: 6px;
  flex-wrap: wrap;
  padding: 0;
  padding: 0 36px;
  outline: none;
  width: auto;

  @media #{$sm-layout} {
    padding: 8px 15px;
    margin: 0 15px;
  }
  @media #{$md-layout} {
    padding: 0 15px;
  }
  @media #{$sm-layout} {
    min-width: auto;
  }

  .label {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
    }
  }

  li,
  button {
    padding: 0 14px;
    color: #101010;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: 0 none;
    @media #{$large-mobile} {
      padding: 0 11px;
    }
    span {
      position: relative;
      display: block;
      padding: 20px 0;
      cursor: pointer;
      @media #{$large-mobile} {
        padding: 11px 0;
      }
      &::after {
        position: absolute;
        left: 0;
        background: #246d62;
        bottom: 0;
        height: 3px;
        content: '';
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 0;
      }
    }
    &.is-checked {
      span {
        color: #246d62;
        &::after {
          width: 100%;
        }
      }
    }
  }
}
