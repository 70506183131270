@font-face {
    font-family: "Bahij TheSansArabic-Plain";
    src: url("../fonts/Bahij_TheSansArabic-Plain.eot?") format("eot"), url("../fonts/Bahij_TheSansArabic-Plain.woff") format("woff"), url("../fonts/Bahij_TheSansArabic-Plain.ttf") format("truetype"), url("../fonts/Bahij_TheSansArabic-Plain.svg#BahijTheSansArabic-Plain") format("svg");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Bahij_TheSansArabic-Bold';
    src: url('../fonts/Bahij_TheSansArabic-Black.ttf') format('truetype'),
        url('../fonts/Bahij_TheSansArabic-Plain.eot?') format('eot'),
        url('../fonts/Bahij_TheSansArabic-Plain.woff') format('woff'),
        url('../fonts/Bahij_TheSansArabic-Plain.svg#BahijTheSansArabic-Plain') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Bahij_TheSansArabic-Bold";
    src: url("../fonts/Bahij_TheSansArabic-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}