/* ====================== [ Start Heading ] ====================== */
.sec-head {
  position: relative;
  margin: 0 auto 80px;
}

@media (max-width: 576px) {
  .sec-head {
    margin: 0 auto 25px;
  }
}

.sec-head h6 {
  color: #777;
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.sec-head h3 {
  font-family: 'Bahij_TheSansArabic-Bold', sans-serif;
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0 8px rgba(255, 255, 255, 0.05);
}

.sec-head .tbg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10vw;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.699);
  opacity: 0.3;
}

.sec-head.tr-head {
  text-align: right;
  margin-bottom: 0;
}

.sec-head.tr-head .tbg {
  text-align: left;
}

.sub-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.sub-title h6 {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.sub-title span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--primary-color);
  position: absolute;
  left: 6px;
  bottom: 5px;
}

.sub-title span:first-of-type {
  bottom: 11px;
}

.sub-title span:last-of-type {
  left: 0px;
}

.main-title {
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 15px;
}

.extra-title {
  font-weight: 700;
}

@media (max-width: 991px) {
  .cont-info {
    margin-top: 50px;
  }
}

.sm-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.smore {
  position: relative;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
}

.smore:after {
  content: "";
  width: 45%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  position: absolute;
  left: 0;
  top: 0;
}

.smore i {
  margin-left: 10px;
}

.client-title h3 {
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 30px;
}

/* ====================== [ End Heading ] ====================== */