/* ====================== [ Start Intro ] ====================== */
@import "./pe-icon.min.css";


.cmd-padding {
    padding: 0 10px;
}

.about_box {
    transition: all 0.5s;
}

.about_box:hover {
    box-shadow: 0px 6px var(--primary-color);
    border: 1px solid #d3a0653d;
}

.pr-about-0 {
    padding-right: 0;
}

@media (max-width: 768px) {
    .pr-about-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.pl-about-0 {
    padding-left: 0;
}

@media (max-width: 768px) {
    .pl-about-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.main-title {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 15px;
}

.sub-title {
    position: relative;
    padding-right: 20px;
    margin-bottom: 20px;
}

.sub-title h6 {
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--primary-color);
}

.sub-title span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    right: 6px;
    bottom: 5px;
}

.sub-title span:first-of-type {
    bottom: 11px;
}

.sub-title span:last-of-type {
    right: 0px;
}

.about .content .co-tit {
    color: var(--primary-color);
    font-weight: 400;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .about .content {
        margin-top: 20px;
    }
}

.about .img-mons .img1,
.about .img-mons .img2,
.about .img-mons .img3 {
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
}

.about .img-mons .img1 img,
.about .img-mons .img2 img,
.about .img-mons .img3 img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
}

.about .img-mons .img2 {
    height: 240px;
}

.about .img-mons .img3 {
    width: 75%;
    margin-top: 20px;
}

.about .blc-img .bimg {
    -webkit-box-shadow: -10px -10px 20px -5px rgba(52, 60, 70, 0.1);
    box-shadow: -10px -10px 20px -5px rgba(52, 60, 70, 0.1);
    position: relative;
}

.about .blc-img .bimg:after {
    content: "";
    position: absolute;
    top: -30px;
    right: -20px;
    width: 100px;
    height: 150px;
    background-image: url(../images/dots.png);
    opacity: 0.4;
    z-index: -1;
}

.about .blc-img .bimg img {
    border-radius: 15px;
    overflow: hidden;
}

.about .blc-img .skills-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #131620;
    padding: 30px;
    border-radius: 15px;
    position: relative;
    margin-top: -80px;
    right: -50px;
    -webkit-box-shadow: 0px 10px 20px -5px rgba(52, 60, 70, 0.1);
    box-shadow: 0px 10px 20px -5px rgba(52, 60, 70, 0.1);
}

.about .blc-img .skills-circle .item:last-of-type {
    margin-right: 30px;
}

.about .ftbox ul {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.about-list {
    flex-direction: row;
}

.about .ftbox ul li div {
    width: 100%;
    padding: 35px 25px;
    text-align: center;
    background: #f7f7f7;
    border-radius: 5px;
    position: relative;
}

@media (max-width: 767px) {
    .about .ftbox ul li div {
        min-height: 175px;
    }
}

.about .ftbox ul li.space {
    margin: 0 10px;
}

.about .ftbox ul li .icon {
    color: var(--primary-color);
    font-size: 30px;
    margin-bottom: 20px;
}

.about .ftbox ul li h6 {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
}

.about .ftbox ul li .dots span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #eee;
    position: absolute;
    right: 10px;
    bottom: 12px;
    opacity: 0.1;
}

.about .ftbox ul li .dots span:first-of-type {
    bottom: 18px;
}

.about .ftbox ul li .dots span:last-of-type {
    right: 16px;
}