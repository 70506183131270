.pv-tab-button {
    display: flex;
    justify-content: center;
    list-style: none;
    background: rgba(26, 255, 175, 0.03);
    min-width: 570px;
    margin: 0 auto;
    border-radius: 6px;
    flex-wrap: wrap;
    padding: 0;
    padding: 0 36px;
    outline: none;
    width: auto;
}

.pv-tab-button li,
.pv-tab-button button {
    padding: 0 14px;
    color: #101010;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: 0 none;
}

.pv-tab-button li span,
.pv-tab-button button span {
    position: relative;
    display: block;
    padding: 20px 0;
    cursor: pointer;
}

.pv-tab-button li span::after,
.pv-tab-button button span::after {
    position: absolute;
    left: 0;
    background: #246D62;
    bottom: 0;
    height: 3px;
    content: "";
    width: 0;
}

button {
    background: transparent;
    border: 1px solid #d4d4d4;
    transition: all 0.4s ease-out 0s;
    color: #1d1d24;
}

pv-tab-button li.is-checked span,
.pv-tab-button button.is-checked span {
    color: #246D62;
}

.pv-tab-button li span::after,
.pv-tab-button button span::after {
    position: absolute;
    left: 0;
    background: #246D62;
    bottom: 0;
    height: 3px;
    content: "";
    width: 0;
}

.pv-tab-button li.is-checked span::after,
.pv-tab-button button.is-checked span::after {
    width: 100%;
}