/* ====================== [ Start Call To Action  ] ====================== */
.call-action {
    background-color: var(--primary-color);
}

.call-action .content h6 {
    color: var(--primary-color);
    background: #fff;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: start;
}

.call-action .content h6 span {
    color: var(--primary-color);
}

.call-action .content h2 {
    text-align: start;
    font-weight: 700;
    font-size: 3vw;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: white;
}

.call-action .content h2 span {
    color: transparent;
}

.call-action .content h2 b span {
    color: #000;
    -webkit-text-stroke-width: 0;
}

.call-action .content p {
    font-size: 17px;
    font-weight: 300;
}

.call-action .btn-curve {
    line-height: 2;
}

.call-action .btn-curve span {
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
}

.call-action .btn-curve:hover span {
    color: #0c0f16;
}

.call-action .tlink {
    margin-left: auto;
    border-bottom: 1px solid #000;
}

.call-action.next {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.call-action.next:after {
    display: none;
}

.call-action.next:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: #0a0a0a;
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.call-action.next .content h2 {
    font-size: 5vw;
    font-weight: 700;
}

.call-action.next .content h2 b {
    font-weight: 700;
}

.call-action.next .nxt-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    background-size: cover;
    background-position: center;
    z-index: -1;
    -webkit-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
}

.call-action.next:hover:before {
    opacity: 0.9;
}

.call-action.next:hover .nxt-img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 10s linear;
    -o-transition: all 10s linear;
    transition: all 10s linear;
}

.calltoaction_button {
    justify-content: end;
}

@media (max-width: 767px) {
    .call-action .content h6 {
        text-align: center;
    }

    .call-action .content h2 {
        text-align: center;
        font-size: 32px;
        line-height: 50px;
    }

    .calltoaction_button {
        justify-content: center;
    }

}

/* ====================== [ End Call To Action  ] ====================== */